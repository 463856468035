import { ComponentType, FC, useEffect } from 'react';
import { CatalogDocumentStoreType } from 'src/applications/base/types/catalog/catalog-document';
import { DocumentStoreType } from 'src/applications/base/types/document/documents';
import { observer } from 'mobx-react-lite';
import { useUnmount } from 'react-use';
import { SearchSummaryWithSettings } from 'src/applications/base/app/react-app/components/common/search-result/SearchSummaryWithSettings';

import classes from './CatalogDocuments.module.scss';
import { useService } from '@redtea/react-inversify';
import {
    DocumentListWithPagingProps
} from '../../../../components/common/DocumentListWithPaging/DocumentListWithPaging';
import { DOCUMENT_LIST_DEP } from '../../../../../../inversify/dependencyIdentifiers';

export interface CatalogDocumentsProps {
    className?: string;
    catalogDocuments: CatalogDocumentStoreType;
    documents: DocumentStoreType;
    catalogItemId?: string;
}

export const CatalogDocuments: FC<CatalogDocumentsProps> = observer((props) => {
    const { className, catalogDocuments, documents, catalogItemId } = props;

    useEffect(() => {
        catalogDocuments.settings.fetchSearchSettings();
    }, [catalogDocuments]);

    useEffect(() => {
        if (catalogItemId) {
            catalogDocuments.settings.setCatalogItem(catalogItemId);
            catalogDocuments.loadNew();
        }
    }, [catalogDocuments, catalogItemId]);

    useUnmount(() => catalogDocuments.clearUp());
    const DocumentListWithPaging = useService<ComponentType<DocumentListWithPagingProps>>(DOCUMENT_LIST_DEP.DocumentListComponent)

    return (
        <section className={className}>
            <SearchSummaryWithSettings
                className={classes.summary}
                search={catalogDocuments}
                sorting
                enableRelevancySortOption={false}
                renderSummary={(count, highlightClass) => (
                    <>
                        <span className={highlightClass}>
                            {'Документы категории'}:&nbsp;
                        </span>
                        <span>{count ?? 0}</span>
                    </>
                )}
            />
            <DocumentListWithPaging
                documents={documents}
                search={catalogDocuments}
                snippetClassName={classes.snippet}
            />
        </section>
    );
});
