import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocumentCardRouteParams } from 'src/applications/base/types/routing';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { DocumentSlice } from 'src/applications/base/types/stores';
import { observer } from 'mobx-react-lite';
import {
    AnchorButton,
    Icon,
    Tab,
    Tabs,
    TabsUnderline,
} from '@nkc-frontend/cat-design';
import { LikeButton } from '../../../components/common/LikeButton';
import { DocumentAdditionalProperties } from '../DocumentAdditionalProperties';
import { DocumentProperties } from '../DocumentProperties';
import { useComputed } from '../../../hooks/useComputed';
import { toJS } from 'mobx';
import isBoolean from 'lodash/isBoolean';

import classes from './DocumentCard.module.scss';
import { DocumentAsideProperties } from '../DocumentAsideProperties';
import { SimilaritySidebar } from '../SimilaritySidebar';
import classNames from 'classnames';
import { DocumentData } from '../DocumentData';
import { NerSideBar } from '../NerSideBar';
import { DocumentsList } from '../DocumentsList';

export type DocumentCardProps = RouteComponentProps<DocumentCardRouteParams>;

export const DocumentCard: FC<DocumentCardProps | any> = observer((props) => {
    const { match } = props;
    const docId = match.params.id;

    const { documents: documentStore } = useStores<DocumentSlice>();

    useEffect(() => {
        const emptyRequest = {
            id: docId,
            from: 0,
            limit: 10,
        };
        documentStore.fetchDocument(docId);
        documentStore.fetchSimilarity(docId);
        documentStore.fetchDocumentsByInn(emptyRequest);
        documentStore.fetchNer(emptyRequest);
        setActiveTab('document');
        //setActiveTab('list');
    }, [docId, documentStore]);
    documentStore.selectNer.subscribe((value) => {
        if (value) {
            setActiveTab('list');
        }
    });

    const document = useComputed(
        () => toJS(documentStore.getDocument(docId)),
        [docId]
    );
    const backUrl = document?.properties?.backUrl;
    const downloadOriginalFile = document?.downloadOriginalFile;
    const documentViewed = useCallback(() => {
        documentStore.documentViewed(
            docId,
            document?.properties?.title ?? 'Без имени'
        );
    }, [docId, documentStore, document]);
    const [activeTabState, setActiveTab] = useState<string | undefined>(
        'document' //'list'
    );

    const isOpen = useComputed(
        () => !!documentStore.sourceDocumentId || !!documentStore.nerDocumentId,
        [documentStore]
    );

    useEffect(() => {
        documentStore.closeSideBar();
    }, [documentStore]);
    const handleBack = () => {
        window.history.back();
    };
    return (
        <div
            className={classNames(classes.container, {
                [classes.open]: isOpen,
            })}
        >
            <div className={classNames(classes.wrap)}>
                <AnchorButton
                    className={classes['back-btn']}
                    visualStyle='plain-link'
                    onClick={handleBack}
                >
                    <span>←</span> Назад
                </AnchorButton>
                <article>
                    <h1 className={classes['title']}>
                        {document?.properties?.title}
                    </h1>
                    {(backUrl || downloadOriginalFile) && (
                        <AnchorButton
                            className={classes['source-btn']}
                            visualStyle='plain-link'
                            href={backUrl ?? ''}
                            target={'_blank'}
                            rel={'noreferrer'}
                            before={
                                <Icon
                                    className={classes['btn-icon']}
                                    icon='source'
                                />
                            }
                            onClick={
                                !backUrl
                                    ? (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          documentStore.downloadDocument(docId);
                                      }
                                    : documentViewed
                            }
                        >
                            Посмотреть оригинал
                        </AnchorButton>
                    )}
                    {document && (
                        <>
                            <LikeButton
                                type='button'
                                liked={document.isFavorite}
                                onLike={
                                    isBoolean(document.isFavorite)
                                        ? (liked) =>
                                              documentStore.requestSetFavorite(
                                                  document.id,
                                                  liked
                                              )
                                        : undefined
                                }
                            />

                            <div className={'mt-5'}>
                                <TabsUnderline>
                                    <Tabs
                                        visualStyle={'fill'}
                                        active={activeTabState}
                                        onChange={(tabVal) => {
                                            setActiveTab(tabVal);
                                            documentStore.closeSideBar();
                                            documentStore.selectNer.next('');
                                        }}
                                    >
                                        <Tab
                                            key={'document'}
                                            value={'document'}
                                        >
                                            О документе
                                        </Tab>
                                        <Tab key={'list'} value={'list'}>
                                            Связи
                                            <span
                                                className={
                                                    activeTabState === 'list'
                                                        ? classes.count
                                                        : 'cd-tab__count'
                                                }
                                            ></span>
                                        </Tab>
                                    </Tabs>
                                </TabsUnderline>

                                <section
                                    className={classes['properties']}
                                    style={{
                                        display:
                                            activeTabState === 'document'
                                                ? 'flex'
                                                : 'none',
                                    }}
                                >
                                    <div
                                        className={classes['properties-right']}
                                    >
                                        <DocumentProperties
                                            document={document}
                                        />
                                        <DocumentAdditionalProperties
                                            className={
                                                classes['additional-properties']
                                            }
                                            document={document}
                                        />
                                        <DocumentData documentCard={document} />
                                    </div>
                                    <div className={classes['properties-left']}>
                                        <DocumentAsideProperties
                                            document={document}
                                        />
                                    </div>
                                </section>

                                <div
                                    style={{
                                        display:
                                            activeTabState !== 'document'
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    <DocumentsList documentId={document.id} />
                                </div>
                            </div>
                        </>
                    )}
                </article>
                <div className={classes.sidebar}>
                    {!documentStore.nerDocumentId ? (
                        <SimilaritySidebar documentId={docId} />
                    ) : (
                        <NerSideBar />
                    )}
                </div>
            </div>
        </div>
    );
});
