import {KeyboardEvent, useCallback, useRef, useState} from 'react';
import { Suggester as CatDesignSuggester } from '@nkc-frontend/cat-design';
import { useStateImperative } from '@nkc-frontend/nkc-react-hooks';
import { SuggesterProps as CatDesignSuggesterProps } from '@nkc-frontend/cat-design/dist/components/Suggester';
import { useEffect, useMemo } from 'react';
import {
    AutocompleteSlice,
    SearchSlice,
} from 'src/applications/base/types/stores';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { observer } from 'mobx-react-lite';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import classes from './Suggester.module.scss';
import { AutocompletionResponseItem } from '../../../../types/search/searchAPI';
import {Link} from 'react-router-dom';
import { useService } from '@redtea/react-inversify';
import { DocumentCardRoute } from '../../../../types/routing';
import { COMMON_DEP } from '../../../../inversify/dependencyIdentifiers';
import IconSprite from '../common/IconSprite/IconSprite';
export interface SuggesterProps {
    className?: string;
    dropdownProps: CatDesignSuggesterProps['dropdownProps'];
    visualStyle: 'main' | 'search';
}

let userSearchQuery: string | null = '';

function renderText(item: AutocompletionResponseItem): string {
    if (!item.offset) {
        return item.text;
    } else if (userSearchQuery) {
        return `${userSearchQuery.substring(0, item.offset)}${item.text}`;
    }
    return '';
}

function renderTextHighlighted(item: AutocompletionResponseItem) {
    return item.highlighted ? (
        <div
            dangerouslySetInnerHTML={{
                __html: item.highlighted,
            }}
        />
    ) : (
        <div>
            {item._renderText || item.text}
        </div>
    );
}

const Suggester: React.FC<SuggesterProps> = observer(
    ({ className, dropdownProps, visualStyle }) => {
        const { search, autocomplete } = useStores<
            SearchSlice & AutocompleteSlice
        >();
        const { document: documentRouting } = useService<DocumentCardRoute>(
            COMMON_DEP.ClientRouting
        );
        const [hoverRowNumber, setHoverRowNumber] = useState(-1);
        useEffect(() => {
            //нужно чтобы это было выполнено 1 раз при рендере компоненты
            setShowHints(false, () => {
                autocomplete.clearAutocomplete();
            });
        }, []); // eslint-disable-line

        const [showHints, setShowHints] = useStateImperative(false);
        const isHintsPanelClicked = useRef(false);
        const isHintSelected = useRef(false);
        const searchQuery = search.settings.searchQuery;
        const queryHints = autocomplete.autocomplete?.items;
        const documentsHints = autocomplete.autocompleteDocuments?.items;

        const getCompletedSearchQuery = useCallback(
            () => searchQuery || '',
            [searchQuery]
        );

        const loadSearchResults = useCallback(() => {
            autocomplete.cancelFetch();
            //очищаем автокомплит после обновления состояния, потому что иначе
            //список с подсказками "дёрнется" от того, что он сначала становится пустым, а потом закрывается
            setShowHints(false, () => {
                autocomplete.clearAutocomplete();
            });
            search.loadNew();
        }, [search, autocomplete, setShowHints]);

        const renderedQueryHints = useMemo(() => {
            const dateNow = Date.now();
            const queryHintClassNames = function (index: number):string {
                return classNames(classes['query-hint'], {
                    [classes['query-hint_style_main']]: visualStyle === 'main',
                    [classes['query-hint_style_search']]: visualStyle === 'search',
                    [classes['query-hint__hover']]: index === hoverRowNumber,
                })
            }
            const addSuggesterOnHover = window.appConfig.addSuggesterOnHover

            const hintsList = queryHints?.map((item, index) => {
                item._renderText = renderText(item);
                return (
                    <div
                        key={`${item.text}_${dateNow}_${index}`}
                        className={queryHintClassNames(index)}
                        onMouseEnter={() => {
                            addSuggesterOnHover && search.settings.setSearchQuery(
                                item._renderText || ''
                            );
                        }}
                        onMouseLeave={() => {
                            addSuggesterOnHover && search.settings.setSearchQuery(
                                userSearchQuery + ''
                            );
                        }}
                        onClick={() => {
                            search.settings.setSearchQuery(
                                item._renderText || ''
                            );
                            loadSearchResults();
                        }}
                    >
                        {renderTextHighlighted(item)}
                    </div>
                );
            });
            let documentsList;
            if (documentsHints?.length) {
                documentsList = documentsHints?.map((item, index) => {
                    return (
                        <div
                            key={`${item.text}_${dateNow}_${index}`}
                            className={queryHintClassNames(index + (hintsList?.length || 0))}
                        >
                            <Link
                                target='_blank'
                                to={documentRouting.getUrl({
                                    id: item.documentId,
                                })}
                                className={classes['query-hint__document']}
                            >
                                <IconSprite name={'document'} size={16} />
                                {renderTextHighlighted(item)}
                            </Link>
                        </div>
                    );
                });
            }

            return (
                <>
                    {hintsList}
                    {documentsHints?.length ? (
                        <div>
                            <p className={queryHintClassNames(-1)}>Документы: </p>
                            {documentsList}
                        </div>
                    ) : (
                        ''
                    )}
                </>
            );
        }, [
            queryHints,
            search.settings,
            visualStyle,
            loadSearchResults,
            documentsHints,
            documentRouting,
            hoverRowNumber,
        ]);

        const suggesterInputRef = useRef<HTMLInputElement>(null);

        useClickAway(suggesterInputRef, () => {
            !isHintsPanelClicked.current && showHints && setShowHints(false);
        });

        useEffect(() => {
            if (
                !queryHints ||
                (!queryHints.length && !documentsHints?.length) ||
                !searchQuery
            ) {
                setShowHints(false);
                setHoverRowNumber(-1);
            } else if(!showHints){
                setShowHints(true);
                setHoverRowNumber(-1);
            }
        }, [queryHints]); // eslint-disable-line

	    function onHover(type: "ArrowDown" | "ArrowUp"): void {
		    if (!showHints) {
			    setHoverRowNumber(-1);
			    return;
		    }
		    const maxCountNumber: number = (queryHints?.length || 0) + (documentsHints?.length || 0);
		    const duration = type === "ArrowDown" ? +1 : -1;
		    const nextElementNumber: number = hoverRowNumber + duration
		    if (!~nextElementNumber || nextElementNumber >= maxCountNumber) {
			    return
		    }
		    setHoverRowNumber(nextElementNumber);
        }

        function onEnter(){
            if(!~hoverRowNumber || (!queryHints?.length && !documentsHints?.length)){
                return
            }
            const queryHintsLength = (queryHints?.length || 0);
            if(queryHints?.length){
                if(hoverRowNumber < queryHints.length){
                    const row = queryHints[hoverRowNumber];
                    search.settings.setSearchQuery(row._renderText || '');
                    loadSearchResults();
                }
            }

            if(documentsHints?.length && hoverRowNumber >= queryHintsLength){
                const row = documentsHints[hoverRowNumber - queryHintsLength];
                const path = documentRouting.getUrl({
                    id: row.documentId,
                })
                window.open(`${document.location.origin}${path}`);
            }
        }

        return (
            <CatDesignSuggester
                ref={suggesterInputRef}
                className={className}
                data-testid={'search-input'}
                autoComplete={'off'}
                value={searchQuery ?? ''}
                onChange={(e) => {
                    const value = e.currentTarget.value;
                    search.settings.setSearchQuery(value);
                    userSearchQuery = value;
                    if (!value) {
                        autocomplete.clearAutocomplete();
                        return;
                    }
                    autocomplete.fetchAutocomplete({ query: value });
                }}
                placeholder={'Поиск'}
                onSearch={() => {
                    isHintSelected.current &&
                        search.settings.setSearchQuery(
                            getCompletedSearchQuery()
                        );

                    loadSearchResults();
                }}
                onClear={() => {
                    search.settings.setSearchQuery('');
                    loadSearchResults();
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if(showHints){
                        if(e.key === "ArrowDown" || e.key === "ArrowUp"){
							onHover(e.key)
                        }
                        if(e.key === "Enter"){
                            onEnter();
                        }
                    }
                }}
                renderHints={() => (
                    <div
                        className={classNames(classes['search-hints-panel'], {
                            [classes['search-hints-header']]:
                                visualStyle === 'search',
                        })}
                        onMouseDown={() => {
                            isHintsPanelClicked.current = true;
                        }}
                        onMouseUp={() => {
                            suggesterInputRef.current?.focus();
                            isHintsPanelClicked.current = false;
                        }}
                    >
                        <div>{renderedQueryHints}</div>
                    </div>
                )}
                dropdownProps={dropdownProps}
                overlayAutocomplete={''}
                showHints={showHints}
            />
        );
    }
);

export default Suggester;
