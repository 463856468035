import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import {
    DocumentSlice,
    PendingRequestSlice,
} from '../../../../../types/stores';
import { Select } from 'antd';
import { SimilarityList } from '../Similarity';
import { Ners } from '../Ners';
import { DocumentsByInn } from '../DocumentsByInn';

export const DocumentsList: FC<{ documentId: string }> = observer(
    ({ documentId }) => {
        const { documents: documentStore } = useStores<
            DocumentSlice & PendingRequestSlice
        >();
        const list = [
            {
                value: '1',
                label: 'По тексту',
            },
            {
                value: '2',
                label: 'По данным',
            },
            {
                value: '3',
                label: 'По ИНН',
            },
        ];

        const [selectType, onChangeSelectType] = useState<any>('1');
        const [nerType, setNerType] = useState<any>();
        documentStore.selectNer.subscribe((value) => {
            if (value) {
                onChangeSelectType('2');
            }
            setNerType(value);
        });
        const emptyRequest = {
            id: documentId,
            from: 0,
            limit: 10,
        };

        return (
            <>
                <h3>Связанные документы</h3>
                <Select
                    options={list}
                    onChange={(value) => {
                        onChangeSelectType(value);
                        if (value === '1') {
                            documentStore.resetFilters();
                            documentStore.updateFilteredSimilarityList();
                        } else if (value === '2') {
                            documentStore.fetchNer(emptyRequest);
                        } else {
                            documentStore.fetchDocumentsByInn(emptyRequest);
                        }
                        setNerType('');
                    }}
                    value={selectType}
                />

                {selectType === '1' && (
                    <SimilarityList documentId={documentId} />
                )}
                {selectType === '2' && (
                    <Ners documentId={documentId} nerFilterValue={nerType} />
                )}
                {selectType === '3' && (
                    <DocumentsByInn documentId={documentId} />
                )}
            </>
        );
    }
);
