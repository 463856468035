import { cloneElement, ComponentType, PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import {
    DocumentListDocStore,
    DocumentListSearchStore, DocumentListWithPagingProps,
} from 'src/applications/base/app/react-app/components/common/DocumentListWithPaging/DocumentListWithPaging';

import classes from './SearchResult.module.scss';
import { FilterPanel } from 'src/applications/base/app/react-app/components/common/FilterPanel/FilterPanel';
import { FilterPanelSearchStore } from 'src/applications/base/app/react-app/components/common/FilterPanel/FilterPanel/FilterPanel';
import { SearchSummarySearchStore } from 'src/applications/base/app/react-app/components/common/search-result/SearchSummaryWithSettings/SearchSummaryWithSettings';
import { useService } from '@redtea/react-inversify';
import { DOCUMENT_LIST_DEP } from '../../../../../../inversify/dependencyIdentifiers';

export interface SearchResultProps {
    search: DocumentListSearchStore &
        FilterPanelSearchStore &
        SearchSummarySearchStore;
    documents: DocumentListDocStore;
    topNode?: ReactElement | null;
    filterNode?: ReactElement | null;
    className?: string;
}

export const SearchResult = (
    props: PropsWithChildren<SearchResultProps>
): JSX.Element => {
    const { search, documents, topNode, filterNode } = props;
    const DocumentListWithPaging = useService<ComponentType<DocumentListWithPagingProps>>(DOCUMENT_LIST_DEP.DocumentListComponent)
    return (
        <div
            className={classNames(classes.results, {
                [classes['results_no-filters']]: !filterNode,
            })}
        >
            <div className={classes.main}>
                {topNode &&
                    cloneElement(topNode, {
                        //пишем так чтобы не переопределять props'ы которые были прописаны вручную
                        search: topNode.props.search ?? search,
                        className:
                            topNode.props.className ?? classes['summary-row'],
                    })}
                {
                    <DocumentListWithPaging search={search} documents={documents} snippetClassName={classes.snippet}/>
                }
            </div>
            {filterNode && (
                <div className={classes.filters}>
                    {cloneElement(filterNode, {
                        search: filterNode.props.search ?? search,
                    })}
                </div>
            )}
        </div>
    );
    
};

SearchResult.defaultProps = {
    filterNode: <FilterPanel />,
};
