import React, { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../../utils/mobx-store-utils/useStores';
import {
    DocumentSlice,
    PendingRequestSlice,
} from '../../../../../types/stores';
import { useComputed } from '../../../hooks/useComputed';
import { useService } from '@redtea/react-inversify';
import { DocumentCardRoute } from '../../../../../types/routing';
import { COMMON_DEP } from '../../../../../inversify/dependencyIdentifiers';
import { FilterTable } from '../../../components/common/admin/tables/FilterTable';
import { TablePaginationConfig } from 'antd/es/table';
import { getColumns } from './getColumn';

export const DocumentsByInn: FC<{ documentId: string }> = observer(
    ({ documentId }) => {
        const { documents: documentStore, pendingRequest } = useStores<
            DocumentSlice & PendingRequestSlice
        >();

        const documentsByInnList = documentStore.documentsByInnList;

        const basePagination = {
            current: 1,
            pageSize: 10,
        };

        const [paginationInit, setPagination] = useState(basePagination);

        const { document: documentRouting } = useService<DocumentCardRoute>(
            COMMON_DEP.ClientRouting
        );

        const totalCount = useComputed(
            () => documentStore.documentsByInnCount,
            [documentStore]
        );

        const startSearch = useCallback(
            ({ current, pageSize }: TablePaginationConfig) => {
                const body: any = {};
                if (current && pageSize) {
                    body.skip = (current - 1) * pageSize;
                    body.limit = pageSize;
                }
                documentStore.fetchDocumentsByInn({
                    id: documentId,
                    from: body.skip,
                    limit: body.limit,
                });
            },
            [documentStore, documentId]
        );

        const onPageChange = useCallback(
            (current: number, pageSize: number = 10) => {
                setPagination({ current, pageSize });
            },
            [setPagination]
        );

        const columns = useMemo(
            () => getColumns(documentRouting),
            [documentRouting]
        );
        const onChange = (pagination: any) => {
            startSearch(pagination);
        };

        const pagination = useMemo(() => {
            return {
                current: paginationInit.current,
                pageSize: paginationInit.pageSize,
                onChange: onPageChange,
                total: totalCount,
            };
        }, [paginationInit, onPageChange, totalCount]);

        return (
            <FilterTable
                loading={pendingRequest.isPending('DOCUMENT_VIEWED')}
                pagination={pagination}
                columns={columns}
                dataSource={documentsByInnList}
                rowKey={(tab) => tab.id}
                onChange={onChange}
            />
        );
    }
);
