import { FC } from 'react';
import classNames from 'classnames';
import { DocumentSnippetComponent } from 'src/applications/base/types/components';
import { DOCUMENT_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { useService } from '@redtea/react-inversify';
import { DocumentSnippet } from 'src/applications/base/types/document/documents';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'antd';
import classes from './DocumentListWithPaging.module.scss';
import {
	CountedItems,
} from 'src/applications/base/app/features/search/stores/SearchResultStore/CountedItems';
import { IPagination } from '../../../base/app/features/paging/IPaging';
import {
	SearchSettingsStore,
} from '../../../base/app/react-app/components/common/FilterPanel/FilterPanel/FilterPanel';
import { useComputed } from '../../../base/app/react-app/hooks/useComputed';


export interface DocumentListDocStore {
	getSnippet(id: string): DocumentSnippet | null;
	
	requestSetFavorite(id: string, favorite: boolean): void;
}

export interface DocumentListSearchStore {
	searchResults: {
		getCurrentResult(): CountedItems | null;
	};
	settings: SearchSettingsStore;
	
	loadMore(prm?: IPagination): void;
	
	loadMoreDocument(prm?: IPagination): void;
}

export interface DocumentListWithPagingProps {
	search?: DocumentListSearchStore;
	documents?: DocumentListDocStore;
	
	className?: string;
	resultsClassName?: string;
	snippetClassName?: string;
	loadMoreBtnClassName?: string;
}

export const DocumentListWithPaging: FC<DocumentListWithPagingProps> = observer(
	(props) => {
		const {
			search,
			documents,
			className,
			snippetClassName,
			resultsClassName,
		} = props;
		const DocumentSnippet = useService<DocumentSnippetComponent>(
			DOCUMENT_DEP.DocumentSnippet,
		);
		
		if (!search || !documents) {
			return null;
		}
		
		const currentTabResults = search.searchResults.getCurrentResult();
		const documentResultList = currentTabResults?.items;
		const current = useComputed(() => {
			const { skip, limit } = search.settings.getCurrentState().paging;
			return skip ? ((skip / limit) + 1) : 1;
		}, [search]);
		const limit = useComputed(() => search.settings.getCurrentState().paging.limit || 1, [search]);
		const notFavorite = useComputed(() => {
			const activeTab = search?.settings.activeTabStore?.getActiveTab();
			return activeTab?.code !== 'favorites';
		}, [search]);
		const total = useComputed(() => {
			const totalCount = search.searchResults.getCurrentResult()?.totalCount || 0;
			return totalCount > 250 ? 250 : totalCount;
		}, [search]);
		const onChange = (page: number, pageSize: number | undefined): void => {
			const pagination: IPagination = {
				pageSize: pageSize || 10,
				page: (page - 1) * (pageSize || 10),
			};
			notFavorite ? search?.loadMore(pagination) : search?.loadMoreDocument(pagination);
		};
		return (
			<div className={className}>
				
				<div
					className={classNames(classes.results, resultsClassName)}
					data-testid={'search-results'}
				>
					{Array.isArray(documentResultList) &&
						documentResultList.map((resultId) => (
							<DocumentSnippet
								className={snippetClassName}
								key={resultId}
								documentSnippet={documents.getSnippet(resultId)}
								onLike={(like) => {
									documents.requestSetFavorite(
										resultId,
										like,
									);
								}}
							/>
						))}
				</div>
				<div className={classes['pagination']}>
					{
						Array.isArray(documentResultList) &&
						<Pagination
							showTotal={()=> ""}
							total={total}
							current={current}
							showSizeChanger={false}
							pageSize={limit}
							onChange={onChange.bind(this)}
						/>
					}
				</div>
			
			
			</div>
		);
	},
);
