//Здесь перечисленны id всех зависимостей

export const AUTHORIZATION_DEP = {
    AuthorizationStore: Symbol.for('AuthorizationStore'),
    AuthorizationService: Symbol.for('AuthorizationService'),
    KeycloakService: Symbol.for('KeycloakService'),
    KeycloakConfigProvider: Symbol.for('KeycloakConfigProvider'),
};

export const SEARCH_DEP = {
    SearchStore: Symbol.for('SearchStore'),
    SearchStoreShouldFetchAlias: Symbol.for('SearchStoreShouldFetchAlias'),
    SearchSettingsStore: Symbol.for('SearchSettingsStore'),
    SearchResultsStore: Symbol.for('SearchResultsStore'),
    SearchTabsStore: Symbol.for('SearchTabsStore'),
    ActiveTabStore: Symbol.for('SearchActiveTabStore'),
    AutocompleteStore: Symbol.for('AutocompleteStore'),
    FilterStore: Symbol.for('SearchFilterStore'),
    FilterValueStore: Symbol.for('SearchFilterValueStore'),
    ActiveFilterStore: Symbol.for('SearchActiveFilterStore'),

    SearchService: Symbol.for('SearchService'),
    SearchTabsService: Symbol.for('SearchTabsService'),
    AutocompleteService: Symbol.for('AutocompleteService'),
    FilterService: Symbol.for('SearchFilterService'),

    SearchRequestBuilder: Symbol.for('SearchRequestBuilder'),
    SearchEventsListener: Symbol.for('SearchEventsListener'),
};

export const CATALOG_DEP = {
    CatalogDocumentStore: Symbol.for('CatalogDocumentStore'),
    CatalogsStore: Symbol.for('CatalogsStore'),
    CatalogsService: Symbol.for('CatalogsService'),
    CatalogItemsStore: Symbol.for('CatalogItemsStore'),
    CatalogItemsService: Symbol.for('CatalogItemsService'),
};

export const DOCUMENT_DEP = {
    DocumentStore: Symbol.for('DocumentsStore'),
    DocumentSnippet: Symbol.for('DocumentSnippetComponent'),
    DocumentService: Symbol.for('DocumentService'),
};

export const PROFILE_DEP = {
    Store: Symbol.for('ActiveUserProfileStore'),
    Service: Symbol.for('ActiveUserProfileService'),
};

export const FAVORITE_DEP = {
    FavoriteStore: Symbol.for('FavoriteStore'),
};

export const NOTIFICATION_DEP = {
    Store: Symbol.for('NotificationStore'),
    Service: Symbol.for('NotificationService'),
};

export const MISC_DEP = {
    Paging: Symbol.for('PagingStore'),
    Sorting: Symbol.for('SortingStore'),
};

export const ADMIN_TABS_DEP = {
    AdminTabStore: Symbol.for('AdminTabStore'),
    AdminTabFilterStore: Symbol.for('AdminTabFilterStore'),
};

export const ADMIN_PROFILES_DEP = {
    AdminProfileStore: Symbol.for('AdminProfileStore'),
};

export const ADMIN_DOCUMENT_SOURCE_DEP = {
    AdminDocumentSourceStore: Symbol.for('AdminDocumentSourceStore'),
};

export const ADMIN_CRAWLER_DEP = {
    AdminCrawlerServersStore: Symbol.for('AdminCrawlerServersStore'),

    AdminCrawlerSourceStore: Symbol.for('AdminCrawlerSourceStore'),

    AdminCrawlerConfigsStore: Symbol.for('AdminCrawlerConfigsStore'),
    AdminCrawlerConfigsService: Symbol.for('AdminCrawlerConfigsService'),
};

export const GENERIC_CRUD_DEP = {
    GenericCRUDService: Symbol.for('GenericCRUDService'),
    RequestNamePrefix: Symbol.for('RequestNamePrefix'),
    UidFieldName: Symbol.for('UidFieldName'),
    OnCreateSuccessHandler: Symbol.for('OnCreateSuccessHandler'),
};

export const ADMIN_NEWS_DEP = {
    AdminNewsStore: Symbol.for('AdminNewsStore'),
};

export const ADMIN_JOBS_DEP = {
    AdminJobsStore: Symbol.for('AdminJobsStore'),
};

export const ADMIN_SCHEDULER_DEP = {
    AdminSchedulerStore: Symbol.for('AdminSchedulerStore'),
};

export const ADMIN_NAMESPACE_DEP = {
    AdminNamespaceStore: Symbol.for('AdminNamespaceStore'),
    AdminNamespaceService: Symbol.for('AdminNamespaceService'),
};

export const ADMIN_NAMESPACE_SCHEMA_DEP = {
    AdminNamespaceSchemaStore: Symbol.for('AdminNamespaceSchemaStore'),
    AdminNamespaceSchemaService: Symbol.for('AdminNamespaceSchemaService'),
};

export const ADMIN_CATALOGS_DEP = {
    AdminCatalogsStore: Symbol.for('AdminCatalogsStore'),
    AdminCatalogsService: Symbol.for('AdminCatalogsService'),
    AdminCatalogItemsStore: Symbol.for('AdminCatalogItemsStore'),
    AdminCatalogItemsService: Symbol.for('AdminCatalogItemsService'),
};

export const ADMIN_JOURNAL_DEP = {
    AdminJournalStore: Symbol.for('AdminJournalStore'),
    AdminJournalService: Symbol.for('AdminJournalService'),
};

export const ADMIN_IDENTITY_SOURCES_DEP = {
    AdminIdentitySourcesStore: Symbol.for('AdminIdentitySourcesStore'),
};

export const ADMIN_PRINCIPAL_DEP = {
    PrincipalStore: Symbol.for('PrincipalStore'),
};

export const COMMON_DEP = {
    RootStore: Symbol.for('RootStore'),
    RootComponent: Symbol.for('RootComponent'),
    RoutesNode: Symbol.for('RoutesNode'),
    ClientRouting: Symbol.for('ClientRouting'),
    History: Symbol.for('History'),
    BaseApiUrlProvider: Symbol.for('BaseApiUrlProvider'),
    HeadersProvider: Symbol.for('HeadersProvider'),
    PendingRequestStore: Symbol.for('PendingRequestStore'),
    RequestErrorStore: Symbol.for('RequestErrorStore'),
    PageTitleProvider: Symbol.for('PageTitleProvider'),
    FileService: Symbol.for('FileService'),
};

export const LOGO_DEP = {
    MainLogoComponent: Symbol.for('MainLogoComponent'),
    HeaderLogoComponent: Symbol.for('HeaderLogoComponent'),
};

export const MENU_DEP = {
    NavigationMenuComponent: Symbol.for('NavigationMenuComponent'),
};

export const FOOTER_DEP = {
    FooterComponent: Symbol.for('FooterComponent'),
};

export const DOCUMENT_LIST_DEP = {
    DocumentListComponent: Symbol.for('DocumentListComponent'),
};

