import 'src/applications/base/stylesheets/index.scss';

import { Container } from 'inversify';
import {
    searchContainer,
    documentsContainer,
    authorizationContainer,
    favoriteContainer,
    miscContainer,
    routingContainer,
    commonDepsContainer,
    notificationsContainer,
    profilesContainer,
} from 'src/applications/base/inversify/inversify.config';
import { setupApp } from 'src/applications/base/setupApp';
import {
    COMMON_DEP,
    AUTHORIZATION_DEP,
    SEARCH_DEP,
    FAVORITE_DEP,
    DOCUMENT_DEP,
    NOTIFICATION_DEP,
    PROFILE_DEP,
} from 'src/applications/base/inversify/dependencyIdentifiers';
import { IRootStore } from 'src/applications/base/app/features/root-store/IRootStore';
import { RootStoreSlices } from 'src/applications/base/types/stores';
import {
    documentListComponent,
    footerContainer,
    logoContainer,
    menuContainer,
} from './inversify.config';



const startApp = () => {
    
    window.appConfig = {
        minScore: 0.1,
        enableRelevancyOption: false,
        visibleLogoInAdmin: false,
        addSuggesterOnHover: false,
    }
    const container = new Container();

    container.load(
        searchContainer,
        documentsContainer,
        authorizationContainer,
        favoriteContainer,
        miscContainer,
        routingContainer,
        commonDepsContainer,
        notificationsContainer,
        profilesContainer,
        logoContainer,
        menuContainer,
        footerContainer,
        documentListComponent,
    );
    

    const rootStore = container.get<IRootStore<RootStoreSlices>>(
        COMMON_DEP.RootStore
    );
    rootStore.register(
        'authorization',
        container.get(AUTHORIZATION_DEP.AuthorizationStore)
    );
    rootStore.register('documents', container.get(DOCUMENT_DEP.DocumentStore));
    rootStore.register('search', container.get(SEARCH_DEP.SearchStore));
    rootStore.register('favorite', container.get(FAVORITE_DEP.FavoriteStore));
    rootStore.register(
        'autocomplete',
        container.get(SEARCH_DEP.AutocompleteStore)
    );
    rootStore.register(
        'pendingRequest',
        container.get(COMMON_DEP.PendingRequestStore)
    );
    rootStore.register(
        'requestError',
        container.get(COMMON_DEP.RequestErrorStore)
    );
    rootStore.register('notifications', container.get(NOTIFICATION_DEP.Store));
    rootStore.register('profiles', container.get(PROFILE_DEP.Store));
    setupApp(container, rootStore);
};

export default startApp;
