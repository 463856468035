import { DocumentByInnModel } from '../../../../../types/document/documents';
import ClientRoute from '../../../../features/utils/client-routing/ClientRoute/ClientRoute';
import { ColumnProps } from 'antd/es/table';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { DATE_FORMAT } from '../../../../utils/constants';
import React from 'react';

export const getColumns = (
    documentRouting: ClientRoute
): ColumnProps<DocumentByInnModel>[] => [
    {
        title: 'Документ',
        key: 'title',
        dataIndex: 'title',
        width: '40%',
        render: (title: string, { id }) => (
            <Link
                className={''}
                to={documentRouting.getUrl({
                    id,
                })}
            >
                {title}
            </Link>
        ),
    },
    {
        title: 'Тип документа',
        key: 'type',
        dataIndex: 'type',
        width: '40%',
        render: (type: string) => <>{type || '-'}</>,
    },
    {
        title: 'Дата создания',
        key: 'created',
        dataIndex: 'created',
        width: '20%',
        render: (created: string) => (
            <>{created ? moment(created).format(DATE_FORMAT) : '-'}</>
        ),
    },
];
