import React from 'react';
import { ReactElement } from 'react';
import { Table as AntTable, TableProps } from 'antd';
import classNames from 'classnames';

import classes from './Table.module.scss';
import { AdminTablePaginationPageSize } from 'src/applications/base/app/utils/constants';
import { showTotal } from '../../../../../../utils/showTotal';

export function Table<T extends Record<string, any>>(
    props: TableProps<T>
): ReactElement {
    const { loading, pagination, className } = props;
    return (

        <>
            <AntTable
                {...props}
                className={classNames(classes.table, className)}
                loading={
                    typeof loading === 'boolean'
                        ? {
                            delay: 400,
                            spinning: loading ? loading : false,
                        }
                        : loading
                }
                pagination={
                    pagination === false
                        ? pagination
                        : {
                            showTotal: showTotal,
                            showSizeChanger: true,
                            pageSizeOptions: [
                                AdminTablePaginationPageSize.SMALL.toString(),
                                AdminTablePaginationPageSize.MEDIUM.toString(),
                                AdminTablePaginationPageSize.LARGE.toString(),
                            ],
                            ...pagination,
                        }
                }
            />
        </>

    );
}
